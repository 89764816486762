import Row from "react-bootstrap/Row";
import style from "./paymentSuccess.module.scss";
import Col from "react-bootstrap/Col";
import {GatsbySrcImage} from "../components/GatsbyImage";
import Container from "react-bootstrap/Container";
import React, { Fragment } from "react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/Layout";

const getData = graphql`
  {
    heart: file(relativePath: { eq: "baby-bib.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const PaymentSuccess = ({location}) => {

    const data = useStaticQuery(getData);
    let order = null;
    let total = 0;

    if(location.state && location.state.result) {
        console.log(location.state.result.paymentIntent)
        order = location.state.result.paymentIntent;
        total = order.amount/100;
    }
    return (
      <Layout
        seoComponent={{
          seoTitle: "payment success",
          seoDescription: "payment success",
        }}
        location={{ pathname: "page-not-found" }}
      >
        <Container>
          <Row className={`${style.thankYouRow} d-flex justify-content-center`}>
            <Col
              lg={10}
              xl={8}
              className="d-flex flex-column align-items-center"
            >
              <h1>Payment Successful!</h1>
              {location.state !== null && (
                <Fragment>
                  <p>Order Id: {order && order.id}</p>
                  <p className="h5 py-3 py-md-4 py-lg-5">
                    Payment of £{total} has been made.
                  </p>
                </Fragment>
              )}
              <h4>
                Thank you for buying a gift through the Little Village "Merry
                Little Christmas" shop and for bringing the joy of a perfect
                present to children this Christmas
              </h4>
              <p>
                <a className="h5 text-secondary" href="/">
                  Return to homepage
                </a>
              </p>
              <div className={`${style.image} py-3 py-md-4 py-lg-5`}>
                <GatsbySrcImage
                  img={data.heart}
                  className={`w-100 h-100`}
                  altText="Bib icon with a heart in the middle"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    )

}
export default PaymentSuccess
